import React from 'react';
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {Helmet} from "react-helmet";
import Images from "../components/elements/images";
import Footer from "../components/footer";
import Header from "../components/header";

function PageSingle({data, location}) {

    const partners = data.allContentfulPartners.nodes;
    const content = data.contentfulPages;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes


    return (
        <Layout>
            <Helmet>
                <title>{content.title} - Zempléni Hiúzok</title>

                <meta property={'og:title'} content={content.title}/>
                <meta property={'og:url'} content={location.href}/>
                <meta property={'og:type'} content={'website'}/>

                <meta name={'twitter:card'} content={'summary'}/>
                <meta name={'twitter:title'} content={content.title}/>
                <meta name={'twitter:url'} content={location.href}/>
            </Helmet>
            <Header pages={pages} teams={teams}/>

            <main>
                <section className="section section-sm bg-gray-100">
                    <div className="container">
                        <div className="row row-30">
                            <div className="col-12">
                                <div className="blog-post">
                                    <h3 className="blog-post-title">{content.title}</h3>

                                    {renderRichText(content.text)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer partners={partners}/>
        </Layout>
    );
}

export default PageSingle;

export const query = graphql`
  query PageBySlug(
    $slug: String!
  ) {
    contentfulPages(slug: {eq: $slug}) {
    title
    slug
    text {
      raw
    }
  }
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
  }
`
